<template>
  <section id="hero">
    <v-sheet
      dark
      tile
    >
      <v-img
        :src="require('@/assets/Main/contact/contact (1).webp')"
        gradient="rgba(0,0,0,.25), rgba(0,0,0,.25)"
        height="40vh"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
        <v-container
          class="fill-height px-1"
          style="padding: 96px 0;"
        >
          <v-row
            align="center"
            justify="center"
          >
            <h1
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'display-1']"
              class=".font-italic font-weight-medium"
            >
              Contact
            </h1>
          </v-row>
        </v-container>
      </v-img>
    </v-sheet>
  </section>
</template>

<script>
  export default {
    name: 'NewsHero'
  }
</script>
